import { Dropdown } from 'bootstrap';
import Cookies from 'js-cookie';
import Chart from 'chart.js/auto';
import { CookieConsent } from './cookieConsent';
import { Loader } from '@googlemaps/js-api-loader';

import 'normalize.css';
import './../css/style.scss';

const app = (function() {
    const menuIconToggle = document.getElementById('menu-button');
    const mainHeaderElm = document.getElementById('main-header');

    // Menu button
    if (menuIconToggle) {
        menuIconToggle.addEventListener('click', menuToggleClick);
    }

    function menuToggleClick(evt) {
        evt.stopPropagation();
        if (mainHeaderElm.classList.contains('opened')) {
            mainHeaderElm.classList.remove('opened');
            mainHeaderElm.classList.add('closed');
        } else {
            mainHeaderElm.classList.remove('closed');
            mainHeaderElm.classList.add('opened');
        }
    }

    /* Cookie Consent Window */
    const cookieConsent = CookieConsent();
    cookieConsent.init({
        'mainElement': document.getElementById('cookie-config'),
        'ageConsentButton': document.getElementById('age-consent-button'),
        'cookieConsentButton': document.getElementById('age-consent-button'),
        'cookieSettingsButton': document.getElementById('cookie-settings-button'),
        'cookieConsentButtonPrimary': document.getElementById('cookie-consent-button-primary'),
        'cookieConsentButtonSecondary': document.getElementById('cookie-consent-button-secondary'),
        'inputAnalyticsCookies': document.getElementById('analytics-cookies'),
        'inputMarketingCookies': document.getElementById('marketing-cookies'),
    });

    /* Alert */
    const alert = document.getElementById('alert');
    const alertUpdated = (alert && alert.dataset.dateUpdated) ? alert.dataset.dateUpdated : 1;
    const alertDisabled = sessionStorage.getItem('alert-disabled');

    if (alertUpdated == alertDisabled) {
        hideAlert();
    }

    if (alert) {
        alert.addEventListener('click', e => {
            e.preventDefault();

            hideAlert();
            sessionStorage.setItem('alert-disabled', alertUpdated);
        });
    }

    function hideAlert() {
        alert.classList.add('hidden');
    }

    /* Flavor wheel */
    const flavourWheelElement = document.getElementById('flavour-wheel');

    if (flavourWheelElement && 'flavour' in flavourWheelElement.dataset === true) {
        const flavourData = JSON.parse(flavourWheelElement.dataset.flavour);

        if (flavourData) {
            const data = {
                labels: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
                ],
                datasets: [{
                    label: '',
                    data: flavourData,
                    fill: true,
                    backgroundColor: 'rgba(137, 204, 192, 1)',
                    // borderColor: 'rgba(255, 99, 132, 0)',
                    // pointBackgroundColor: 'rgba(255, 99, 132, 0)',
                    // pointBorderColor: 'rgba(255, 99, 132, 0)',
                    // pointHoverBackgroundColor: '#fff',
                    // pointHoverBorderColor: 'rgb(255, 99, 132)'
                    }],
            };

            const config = {
                type: 'radar',
                data: data,
                options: {
                    animation: false,
                    elements: {
                        line: {
                            borderWidth: 0,
                        },
                        point: {
                            pointRadius: 0
                        }
                    },
                    scales: {
                        r: {
                            angleLines: {
                                display: false
                            },
                            display: false,
                            suggestedMin: 0,
                            suggestedMax: 100,
                        }
                    },
                    plugins: {
                        labels: {

                        },
                        legend: {
                            display: false,
                            title: {
                                display: false,
                            }
                        },
                        tooltip: {
                            enabled: false,
                        }
                    }
                },
            };

            const myChart = new Chart(flavourWheelElement, config);
        }
    }

    // Parking map
    const mapParking = document.getElementById('map-parking');
    if (mapParking) {
        createMapParking();
    }

})();

function createMapParking() {
    let map;
    const mapElement = document.getElementById('map-parking');
    const loader = new Loader({
      apiKey: "AIzaSyDB5OyyPNyx6EOOe3O_eVqBSv0W9geMgbs",
      version: "weekly",
      libraries: ["places"],
    });

    const mapOptions = {
        center: {
            lat: 0,
            lng: 0,
        },
        zoom: 4,
        disableDefaultUI: true,
    };

    // Promise
    loader
        .load()
        .then((google) => {
            map = new google.maps.Map(mapElement, mapOptions);
            setupMapParking(map);
        })
        .catch(e => {
        // do something
        });
}

function setupMapParking(map) {
    const styles = {
        default: [],
        silver: [
          {
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }],
          },
          {
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            elementType: "labels.text.fill",
            stylers: [{ color: "#616161" }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#f5f5f5" }],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [{ color: "#bdbdbd" }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#eeeeee" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#757575" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#e5e5e5" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [{ color: "#757575" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#dadada" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#616161" }],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [{ color: "#e5e5e5" }],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [{ color: "#eeeeee" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#c9c9c9" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
        ],
        night: [
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
        retro: [
          { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
          { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
          { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{ color: "#c9b2a6" }],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "geometry.stroke",
            stylers: [{ color: "#dcd2be" }],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [{ color: "#ae9e90" }],
          },
          {
            featureType: "landscape.natural",
            elementType: "geometry",
            stylers: [{ color: "#dfd2ae" }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#dfd2ae" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#93817c" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#a5b076" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#447530" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#f5f1e6" }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#fdfcf8" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#f8c967" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#e9bc62" }],
          },
          {
            featureType: "road.highway.controlled_access",
            elementType: "geometry",
            stylers: [{ color: "#e98d58" }],
          },
          {
            featureType: "road.highway.controlled_access",
            elementType: "geometry.stroke",
            stylers: [{ color: "#db8555" }],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [{ color: "#806b63" }],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [{ color: "#dfd2ae" }],
          },
          {
            featureType: "transit.line",
            elementType: "labels.text.fill",
            stylers: [{ color: "#8f7d77" }],
          },
          {
            featureType: "transit.line",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ebe3cd" }],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [{ color: "#dfd2ae" }],
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [{ color: "#b9d3c2" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#92998d" }],
          },
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ],
        hiding: [
          {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ],
      };
    
    // const features = [
    //     { type: 'batteliek', lat: 51.0407491, lng: 4.4439851 },
    //     { type: 'parking', lat: 51.039430, lng: 4.446747 },
    //     { type: 'parking', lat: 51.038769, lng: 4.445100 },
    //     { type: 'bike', lat: 51.040559, lng: 4.443476},
    //     { type: 'bike', lat: 51.040252, lng: 4.444116 },
    //     { type: 'noParking', lat: 51.040658, lng: 4.443362 },
    //     { type: 'noParking', lat: 51.041090, lng: 4.443292 }
    // ];

    // const iconBase = "https://developers.google.com/maps/documentation/javascript/examples/full/images/";
    const iconBase = "/assets/";

    const iconBatteliek = {
        url: iconBase + "marker-batteliek.png", // url
        scaledSize: new google.maps.Size(30, 40), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };

    const iconParking = {
        url: iconBase + "marker-parking.png", // url
        scaledSize: new google.maps.Size(30, 40), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };

    const iconNoParking = {
        url: iconBase + "marker-no-parking.png", // url
        scaledSize: new google.maps.Size(30, 40), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };

    const iconBike = {
        url: iconBase + "marker-bike.png", // url
        scaledSize: new google.maps.Size(30, 40), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };

    const icons = {
        parking: {
            icon: iconParking,
        },
        batteliek: {
            icon: iconBatteliek,
        },
        bike: {
            icon: iconBike,
        },
        noParking: {
            icon: iconNoParking,
        },
    };

    // Create Markers
    let bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < features.length; i++) {
      const marker = new google.maps.Marker({
          position: new google.maps.LatLng(features[i].lat, features[i].lng),
          icon: icons[features[i].type].icon,
          map: map,
      });

      if (features[i].infoContent) {
        let content = '';

        if (features[i].infoTitle) {
          content += '<h3>' + features[i].infoTitle + '</h3>';
        }

        if (features[i].infoContent) {
          content += features[i].infoContent;
          const destination = 'https://www.google.com/maps/dir/?api=1&destination=' + features[i].lat + ',' + features[i].lng;
          content += '<p><a href="' + destination + '" target="_blank">route</a></p>';
        }

        if (content) {
          const infowindow = new google.maps.InfoWindow({
            content: '<div class="info-window">' + content + '</div>',
            ariaLabel: features[i].infoTitle,
          });

          marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              map,
            });
          });
        }
      }

      if (marker.getVisible()) {
          bounds.extend(marker.getPosition());
      }
    }

    map.fitBounds(bounds);
    map.setOptions({ styles: styles['retro'] });
}